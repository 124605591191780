/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { findBySlug } from "../helpers"
import Hero from "../components/hero"
import Page from "../components/page"
import PressCenter from "../components/press-center"
import { paramsCheck } from "../functions/functions"

const PressCenterPage = ({ data }) => {
  paramsCheck()
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "press-center-hero-module",
    })
    const heroHeights = [200, 300]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
      ></Hero>
    )
  }
  const renderPressCenter = () => {
    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
          px: theme => theme.spacing.horizontal,
        }}
      >
        <PressCenter
          news={data.news.nodes}
          talks={data.talks.nodes}
          data={data.contentfulPage}
        />
      </div>
    )
  }
  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderPressCenter()}
    </Page>
  )
}

export default PressCenterPage

export const query = graphql`
  query pressCenterPageQuery {
    contentfulPage(slug: { eq: "press-center" }) {
      ...PageData
      ...BladePatternData
    }
    talks: allContentfulNews(filter: { type: { eq: "Recorded Talks" } }) {
      nodes {
        link
        date
        title
      }
    }
    news: allContentfulNews(filter: { type: { eq: "Press" } }) {
      nodes {
        title
        teaser {
          teaser
        }
        link
        slug
        image {
          file {
            url
          }
          description
        }
        categoryInTheNewsOnly
        publicationTitle
        date
      }
    }
  }
`
